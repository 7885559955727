import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'react-bootstrap/Image'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import MenuCard from './MenuCard'
import FormDialog from './FormDialog'
import Notification from './Notification'
import RateModal from './RateModal'
import InfoDialog from './InfoDialog'
import apiService from '../services/apiService'
import { loginImg, lucaLogoGifSM } from '../assets'
import {
  wordSearch,
  getAvatar,
  getAvatarSm,
  getPermissionType,
} from '../services/lucaFunctions'

function NavBar(props) {
  const { setting } = props
  const { auth, updateAuth, handleLoginShow, handleLogOut, handleAuthChange } =
    setting
  const location = useLocation()
  useEffect(() => {
    if (
      location.pathname === '/' &&
      location.search === '?action=login' &&
      auth.status === 'unauthed'
    ) {
      location.search = ''
      handleLoginShow()
    }
  })

  const [formData, setformData] = useState({
    avatar_id: `${auth.auth.avatar_id}`,
    content: [],
  })
  const avatarForm = {
    pageLimit: 1,
    content: [
      {
        // name: '選 擇 使 用 者 頭 像',
        label: 'avatar_id',
        name: 'avatar_id',
        avatar: getAvatar(formData.avatar_id),
        placeholder: '選 擇 頭 像...',
        type: 'select',
        subtype: 'avatar',
        value: formData.avatar_id,
        content: [
          { name: 'Default', value: 1 },
          { name: 'Worker', value: 2 },
          { name: 'Viewer', value: 3 },
          { name: 'InfoProcessing', value: 4 },
          { name: 'Analyst', value: 5 },
          { name: 'Financial', value: 6 },
          { name: 'Administrator', value: 7 },
          { name: 'VIP', value: 8 },
          { name: 'Developer', value: 9 },
          { name: 'Ghost', value: 10 },
        ],
      },
    ],
    handleSendForm: async (value) => {
      const res = await apiService.data({
        path: `luca/user/${auth.auth.user_id}`,
        method: 'put',
        data: value,
      })
      if (!res.error) updateAuth(res)
    },
  }
  const [form, setform] = useState(avatarForm)
  const [showInfo, setshowInfo] = useState(false)
  const handleShowInfo = () => setshowInfo(true)
  const handleCloseInfo = () => {
    setshowInfo(false)
  }

  const applyForm = {
    title: '申 請 A D M',
    titleEng: 'Apply a new ADM',
    size: 'md',
    pageLimit: 1,
    type: 'apply BM',
    setting: { platform: 'Luca' },
    content: [
      {
        name: 'ADM名稱',
        label: 'ADM名稱',
        placeholder: '輸入名稱...',
        type: 'text',
        required: true,
      },
      {
        name: '客戶編號',
        label: '客戶編號',
        placeholder: '輸入編號...',
        type: 'text',
      },
    ],
    handleSendForm: async (value) => {
      const data = {}
      Object.keys(value.content)
        .filter((key) => {
          const content = form.content.filter((f) => f.name === key)
          if (content.length > 0) {
            return content.find(
              (c) =>
                !c.dependency ||
                value.content[c.dependency.name] === c.dependency.value
            )
          }
          return true
        })
        .forEach((key) => {
          data[key] = value.content[key]
        })
      const res = await apiService.form('post', {
        ...value,
        type: 'apply BM',
        setting: { status: '待 處 理' },
        content: data,
        user: auth.auth.user_id,
        business: auth.account.business_id,
      })
      if (!res.error) handleShowInfo(true)
    },
  }

  // change avatar
  const [showForm, setshowForm] = useState(false)
  const handleShowForm = (type) => {
    switch (type) {
      case 'avatar':
        setform(avatarForm)
        setformData({
          avatar_id: `${auth.auth.avatar_id}`,
        })
        break
      case 'apply':
        setform(applyForm)
        setformData({
          content: [],
        })
        break
      default:
        setformData({
          avatar_id: `${auth.auth.avatar_id}`,
        })
        setform(avatarForm)
        break
    }
    setshowForm(true)
  }
  useEffect(() => {
    setformData({ ...formData, avatar_id: `${auth.auth.avatar_id}` })
  }, [auth.auth.avatar_id])

  const handleDataChange = (event) => {
    setformData(
      formData.content
        ? {
            ...formData,
            content: {
              ...formData.content,
              [event.target.name]: event.target.value,
            },
          }
        : { ...formData, [event.target.name]: event.target.value }
    )
  }
  const handleCloseForm = (value) => {
    setshowForm(false)
    if (value) form.handleSendForm(formData)
  }

  // bm search
  const [search, setsearch] = useState('')

  // rate dialog
  const [rateOpen, setrateOpen] = useState(false)
  const handleRateOpen = () => setrateOpen(true)

  const handleCloseRateForm = (value) => {
    setrateOpen(false)
    if (value) form.handleSendForm()
  }

  return (
    <>
      <Navbar
        bg="lucaImg"
        expand="lg"
        sticky="top"
        style={{
          zIndex: '1500',
          padding: '0.7rem',
          backgroundSize: 'cover',
          height: '8vh',
          overflowX: 'clip',
        }}
      >
        <Container fluid className="px-4">
          {location.pathname !== '/Welcome' && (
            <>
              <Navbar.Brand className="text-white px-2">
                <Link to="/">
                  <Image
                    src={lucaLogoGifSM}
                    height="35"
                    className="d-inline-block align-top"
                    alt="logo"
                    title="回 首 頁"
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Text className="fs-7 fw-light text-white pt-2">
                全&ensp;球&ensp;數&ensp;位&ensp;媒&ensp;體&ensp;領&ensp;航&ensp;者&emsp;Ｘ&emsp;一&ensp;站&ensp;式&ensp;企&ensp;業&ensp;行&ensp;銷&ensp;資&ensp;源&ensp;整&ensp;合&ensp;平&ensp;台
              </Navbar.Text>
            </>
          )}

          <div className="ms-auto d-flex">
            {auth.status === 'authed' ? (
              <>
                {/* rate */}
                {auth.accounts && auth.account.business_id === 1 && (
                  <Navbar.Collapse
                    className="d-flex"
                    title="各幣別匯率預設值管理"
                  >
                    <Nav className="d-flex ms-auto me-3 my-auto">
                      <FontAwesomeIcon
                        icon={faDollarSign}
                        className="text-white fs-5"
                        id="nav-dropdown-bell"
                        style={{
                          cursor: 'pointer',
                          paddingTop: '0.4rem',
                          paddingBottom: '0.25rem',
                        }}
                        onClick={handleRateOpen}
                      />
                    </Nav>
                  </Navbar.Collapse>
                )}
                {/* Bell */}
                {auth.accounts && (
                  <Notification
                    setting={{
                      auth,
                      handleAuthChange,
                      name: '通 知',
                    }}
                  />
                )}
                {/* BM selector */}
                {auth.accounts && (
                  <Navbar.Collapse id="navbar-nav-user" title="切 換 ADM 帳 戶">
                    <Nav className="BMselector nav-icons ms-auto form-floating">
                      <NavDropdown
                        title={`${auth.account.name}（${auth.auth.name}）`}
                        align="end"
                        id={auth.status}
                      >
                        <NavDropdown.Item
                          title="個 人 帳 戶"
                          className="px-3 py-2 d-flex justify-content-between border-bottom"
                          href=""
                          disabled
                        >
                          {auth.auth.name}
                          <small className="text-greyLight my-auto ms-auto px-1">
                            personal
                          </small>
                          <FontAwesomeIcon
                            icon={faUserCircle}
                            className="align-self-center text-greyLight fs-5"
                          />
                        </NavDropdown.Item>
                        <InputGroup
                          style={{ borderRadius: '.25rem' }}
                          className="m-2 w-90 border rounded-lg"
                          size="sm"
                        >
                          <FormControl
                            type="text"
                            placeholder="Search ADM..."
                            aria-label="Search ADM..."
                            aria-describedby="btnGroupAddon"
                            title="輸 入 關 鍵 字 搜 尋 ADM 帳 戶"
                            value={search}
                            onChange={(event) => setsearch(event.target.value)}
                          />
                        </InputGroup>
                        <div
                          className="scrollbarShow"
                          style={{ maxHeight: '25rem', overflowY: 'auto' }}
                        >
                          {auth.accounts
                            .filter(
                              (account) =>
                                account.business_id &&
                                wordSearch(account.name, search)
                            )
                            .map((account) => (
                              <NavDropdown.Item
                                title={account.name}
                                className="px-3 py-2 App-oneLineEllipsis d-block"
                                key={account.business_id}
                                href=""
                                onClick={() =>
                                  handleAuthChange(account.business_id, '/')
                                }
                              >
                                {account.name}
                              </NavDropdown.Item>
                            ))}
                        </div>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                )}

                {/* User */}
                <Navbar.Collapse
                  id="navbar-nav-user"
                  title={getPermissionType(auth.account)}
                >
                  <Nav className="nav-icons ms-auto noDropdownIcon">
                    <NavDropdown
                      title={
                        <img
                          src={getAvatarSm({
                            avatarId: auth.auth.avatar_id,
                            permission: auth.account
                              ? auth.account.permission
                              : {},
                          })}
                          alt="User"
                        />
                      }
                      align="end"
                      id={auth.status}
                    >
                      {/* User image */}
                      <div
                        className={auth.auth.avatar_id ? 'App-UserImgEdit' : ''}
                        title={
                          auth.auth.avatar_id
                            ? '更 換 頭 像'
                            : getPermissionType(auth.account)
                        }
                        onClick={() => {
                          if (auth.auth.avatar_id) handleShowForm()
                        }}
                        aria-hidden="true"
                      />
                      <Image
                        style={{ margin: '0 auto' }}
                        className="d-flex justify-content-center align-self-center pt-3"
                        src={getAvatar({
                          avatarId: auth.auth.avatar_id,
                          permission: auth.account
                            ? auth.account.permission
                            : {},
                        })}
                        alt="頭 像"
                        width="35%"
                        height="35%"
                      />
                      <MenuCard
                        className="pb-0"
                        setting={{
                          auth,
                          handleLogOut,
                        }}
                      />
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </>
            ) : (
              <Nav.Link
                onClick={handleLoginShow}
                className="App-notextDecoration text-white"
              >
                <Image src={loginImg} alt="login" width="20px" height="20px" />
              </Nav.Link>
            )}
          </div>
        </Container>
      </Navbar>
      <FormDialog
        setting={{
          size: 'md',
          show: showForm,
          form,
          formData,
          handleDataChange,
          handleClose: handleCloseForm,
          hasFooter: true,
        }}
      />
      <InfoDialog show={showInfo} handleClose={handleCloseInfo} />
      <RateModal
        setting={{
          show: rateOpen,
          handleClose: handleCloseRateForm,
        }}
      />
    </>
  )
}

NavBar.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default NavBar
