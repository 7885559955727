import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faTrashAlt,
  faEdit,
  faTasks,
} from '@fortawesome/free-solid-svg-icons'
import { faFlushed } from '@fortawesome/free-regular-svg-icons'
import {
  SearchBar,
  SelectBar,
  Datatable,
  FormDialog,
  ConfirmForm,
  BMManager,
} from '../components'
import apiService from '../services/apiService'
import { departs, wordSearch, getAvatar } from '../services/lucaFunctions'

function AllUsers() {
  const [users, setusers] = useState([])
  const [filteredUser, setfilteredUser] = useState(users)
  const [tempSearch, settempSearch] = useState('')
  const [filter, setfilter] = useState({
    depart: '',
    sort: JSON.stringify({ field: 'name', order: 'desc' }),
    search: '',
  })
  const { depart, sort, search } = filter
  const handleFilterChange = (e) =>
    setfilter({
      ...filter,
      [e.target.name]: e.target.value,
      search: tempSearch,
    })
  useEffect(() => {
    apiService
      .data({
        path: `luca/user`,
        method: 'get',
      })
      .then((value) => {
        setusers(value.users)
        setfilteredUser(value.users)
      })
  }, [])
  useEffect(() => {
    setfilteredUser(
      users
        ? users
            .filter(
              (user) =>
                (wordSearch(user.name, search) ||
                  wordSearch(user.email, search) ||
                  wordSearch(user.setting.remark || '', search)) &&
                (depart === '' || user.setting.depart === depart)
            )
            .sort((a, b) => {
              const { field, order } = JSON.parse(sort)
              return order === 'aesc'
                ? a[field] - b[field] || new Date(a[field]) - new Date(b[field])
                : b[field] - a[field] || new Date(b[field]) - new Date(a[field])
            })
        : []
    )
  }, [search, users, sort, depart])

  const [show, setshow] = useState(false)
  const [bms, setbms] = useState([])
  useEffect(() => {
    apiService.data({ path: `luca/business`, method: 'get' }).then((value) => {
      setbms(value)
    })
  }, [])
  const [managed, setmanaged] = useState([])
  const [selectedUser, setselectedUser] = useState('')
  const getManaged = async (id) => {
    const res = await apiService.data({
      path: `luca/businessUser/${id}`,
      method: 'get',
    })
    if (!res.error) {
      setselectedUser(id)
      setmanaged(res)
      setshow(true)
    }
  }
  const handleClose = () => {
    setshow(false)
  }

  // warn control
  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldMatch: '',
    shouldReturn: '',
  })
  const handleShowWarn = (value, target) => {
    setshowWarn({
      ...value,
      show: true,
      target,
    })
  }
  const handleWarnClose = async (value) => {
    setshowWarn({
      ...showWarn,
      show: false,
    })
    if (value) {
      const res = await apiService.data({
        path: `luca/user/${value}`,
        method: 'delete',
      })
      if (!res.error) setusers(users.filter((user) => user.user_id !== value))
    }
  }

  // add and delete features
  const handleAddADM = async (business) => {
    const res = await apiService.data({
      path: `luca/businessUser/${selectedUser}/${business}`,
      method: 'post',
      data: {
        isAdmin: true,
        isAdsManager: false,
        isAnalyst: false,
        isFinance: false,
      },
    })
    if (!res.error) {
      setmanaged([...managed, res])
    }
  }

  const handleDeleteADM = async (business) => {
    const res = await apiService.data({
      path: `luca/businessUser/${selectedUser}/${business}`,
      method: 'delete',
    })
    if (!res.error) {
      setmanaged(managed.filter((m) => m.business_id !== business))
    }
  }

  const handlePermissionChange = async (business, setting) => {
    const res = await apiService.data({
      path: `luca/businessUser/${selectedUser}/${business}`,
      method: 'put',
      data: { setting },
    })
    if (res && !res.error) {
      setmanaged(
        managed.map((m) =>
          m.business_id !== business ? m : { ...m, setting: res.setting }
        )
      )
    }
  }

  // form for add and delete business
  const [form, setform] = useState({
    pageLimit: 1,
    content: [],
  })
  const [formData, setformData] = useState({ content: {} })
  const [showForm, setshowForm] = useState(false)

  const usedEmails = users.map((u) => u.email)
  const addUserForm = {
    title: '建 立 使 用 者',
    titleEng: 'Add a new user',
    size: 'md',
    pageLimit: 1,
    type: 'New User',
    setting: { platform: 'Luca' },
    content: [
      {
        label: 'name',
        name: 'name',
        type: 'text',
        placeholder: 'Enter Name...',
        required: true,
      },
      {
        label: 'depart',
        name: 'depart',
        type: 'text',
        placeholder: 'Enter Department...',
        required: true,
      },
      {
        label: 'email',
        name: 'email',
        type: 'email',
        placeholder: 'Enter Email...',
        required: true,
        constraint: {
          text: 'Email 已被使用',
          method: (value) => !usedEmails.includes(value),
        },
      },
      {
        label: 'password',
        name: 'password',
        type: 'password',
        placeholder: 'Enter Password...',
        required: true,
      },
    ],
    handleSendForm: async (value) => {
      const res = await apiService.register(value)
      if (!res.error) {
        setusers(users.concat(res))
      }
    },
  }

  const getEditUserForm = (id) => {
    const target = users.find((u) => u.user_id === id)
    if (!target) return false
    return {
      title: '使 用 者 資 料 修 改',
      titleEng: 'edit user profile',
      size: 'md',
      pageLimit: 1,
      type: 'Edit User',
      setting: { platform: 'Luca' },
      content: [
        {
          label: 'name',
          name: 'name',
          type: 'text',
          placeholder: 'Enter Name...',
          value: target.name,
          required: true,
        },
        {
          label: 'depart',
          name: 'depart',
          type: 'text',
          placeholder: 'Enter Department...',
          value: target.setting.depart,
          required: true,
        },
        {
          label: 'email',
          name: 'email',
          type: 'email',
          placeholder: 'Enter Email...',
          value: target.email,
          required: true,
          constraint: {
            text: 'Email 已被使用',
            method: (value) => !usedEmails.includes(value),
          },
        },
      ],
      handleSendForm: async (value) => {
        const res = await apiService.data({
          path: `luca/user/${id}`,
          method: 'put',
          data: {
            name: value.name,
            email: value.email,
            setting: JSON.stringify({
              ...target.setting,
              depart: value.depart,
            }),
          },
        })
        if (!res.error) {
          setusers(users.map((u) => (u.user_id === id ? { ...u, ...res } : u)))
        }
      },
    }
  }

  const handleShowForm = (value) => {
    if (!value) return
    const content = {}
    value.content.forEach((c) => {
      content[c.name] = c.value ? c.value : ''
    })
    setformData({ ...value, content })
    setform({
      ...value,
      // fixedContent: [...value.content].filter((c) => c.type === 'fixed'),
      content: [...value.content],
    })
    setshowForm(true)
  }
  const handleDataChange = (event) => {
    setformData({
      ...formData,
      content: { ...formData.content, [event.target.name]: event.target.value },
    })
  }
  const handleCloseForm = (value) => {
    setshowForm(false)
    if (value) form.handleSendForm(formData.content)
  }

  return (
    <Container fluid className="authManage card px-2">
      <Row className="p-4 pageTitle">
        <h4 className="pt-4 pb-1">全 系 統 使 用 者 管 理</h4>
        <p>User account creation and management</p>
        <p className="pageSubtitle pt-4">
          潮 網 人 員 可 在 此 創 建 使 用 者 及 配 發 資 源 權 限
        </p>
      </Row>
      <Row className="pt-3 pb-0 px-4">
        <Col xs="3" className="pe-0">
          <SelectBar
            setting={{
              name: 'depart',
              method: handleFilterChange,
              placeholder: '選擇部門',
              content: departs.concat({
                name: '其他',
                value: '',
              }),
            }}
          />
        </Col>
        <Col xs="3">
          <SelectBar
            setting={{
              method: (e) =>
                handleFilterChange({
                  target: {
                    name: 'sort',
                    value:
                      e.target.value ||
                      JSON.stringify({ field: 'name', order: 'desc' }),
                  },
                }),
              value: sort,
              placeholder: '選擇排序',
              content: [
                {
                  name: '排序 － 由最新至最舊',
                  value: JSON.stringify({
                    field: 'created_on',
                    order: 'desc',
                  }),
                },
                {
                  name: '排序 － 由最舊至最新',
                  value: JSON.stringify({
                    field: 'created_on',
                    order: 'aesc',
                  }),
                },
              ],
            }}
          />
        </Col>
        <Col xs="4" className="ps-0">
          <SearchBar
            setting={{
              title: '請輸入搜尋關鍵字...',
              name: 'search',
              tempSearch,
              settempSearch,
              method: () =>
                handleFilterChange({
                  target: {
                    name: 'search',
                    value: tempSearch,
                  },
                }),
            }}
          />
        </Col>
        <Col xs="2" className="ps-0 ms-auto">
          <Button
            className="w-100"
            variant="luca"
            title="建 立 新 使 用 者"
            onClick={() => handleShowForm(addUserForm)}
          >
            新增使用者
            <FontAwesomeIcon icon={faPlusCircle} className="fs-6 ms-2" />
          </Button>
        </Col>
      </Row>
      <Row className="px-4 pt-3 pb-2 h-100 tableLayout-adj tableW-adj overflow-auto">
        <Datatable
          setting={{
            hasCheckBox: false,
            hasButton: false,
            hasPagination: true,
            pageSize: 5,
            headers: ['名 稱', '建 立 日 期', '所 屬 單 位', '功 能'],
            content: filteredUser
              .concat(
                Array(
                  filteredUser.length % 5 > 0
                    ? 5 - (filteredUser.length % 5)
                    : 0
                ).fill({
                  fill: true,
                })
              )
              .map((user) =>
                user.fill
                  ? {
                      type: { value: '- -' },
                      createdAt: { value: '- -' },
                      departs: { value: '- -' },
                      func: { value: '- -' },
                    }
                  : {
                      type: {
                        value: (
                          <div className="d-flex justify-content-center ">
                            <Image
                              style={{ width: '25px' }}
                              className="d-flex py-2 my-auto me-2"
                              src={getAvatar(user.avatar_id)}
                              alt="頭 像"
                            />
                            <p className="my-auto">{user.name}</p>
                          </div>
                        ),
                      },
                      createdAt: {
                        value: moment(user.created_on).format('YYYY-MM-DD'),
                      },
                      departs: {
                        value: (
                          <div className="text-center">
                            {user.setting.depart || '- -'}
                          </div>
                        ),
                      },
                      func: {
                        value: (
                          <div className="formsTool">
                            <Button
                              variant="lucaIcon"
                              onClick={() => getManaged(user.user_id)}
                            >
                              <FontAwesomeIcon
                                className="ms-auto align-self-center h5"
                                icon={faTasks}
                                title="廣 告 帳 戶 管 理"
                              />
                            </Button>
                            <Button
                              variant="lucaIcon"
                              onClick={() => {
                                handleShowForm(getEditUserForm(user.user_id))
                                setselectedUser(user.user_id)
                              }}
                            >
                              <FontAwesomeIcon
                                className="ms-auto align-self-center h5"
                                icon={faEdit}
                                title="編 輯"
                              />
                            </Button>
                            <Button
                              variant="lucaIcon"
                              onClick={() =>
                                handleShowWarn(
                                  {
                                    ...user,
                                    title: '刪 除 使 用 者',
                                    titleEng: 'Delete User',
                                    img: faFlushed,
                                    text: (
                                      <h6>是否刪除「{user.name}」使用者？</h6>
                                    ),
                                    warn: (
                                      <h6 className="text-danger py-2">
                                        【警告】此動作無法復原，輸入使用者名稱並確認。
                                      </h6>
                                    ),
                                    shouldMatch: user.name,
                                    shouldReturn: user.user_id,
                                  },
                                  'business'
                                )
                              }
                            >
                              <FontAwesomeIcon
                                className="ms-auto align-self-center h5"
                                icon={faTrashAlt}
                                title="刪 除"
                              />
                            </Button>
                          </div>
                        ),
                      },
                      method: () => {},
                    }
              ),
          }}
        />
      </Row>
      <BMManager
        setting={{
          show,
          handleClose,
          size: 'xl',
          title: '使 用 者 A D M 管 理',
          titleEng: 'User ADM management',
          managed,
          items: bms,
          assigned: managed,
          handleAdd: handleAddADM,
          handleDelete: handleDeleteADM,
          handlePermissionChange,
          action: 'bms',
        }}
      />
      <FormDialog
        setting={{
          size: 'md',
          show: showForm,
          form,
          formData,
          handleDataChange,
          handleClose: handleCloseForm,
          btnText: '送 出',
          hasFooter: true,
        }}
      />
      <ConfirmForm
        setting={{
          ...showWarn,
          size: 'md',
          warning: (
            <div className="text-center">
              <div className="my-4">
                <FontAwesomeIcon
                  className="mx-auto display-1 text-luca"
                  icon={showWarn.img}
                />
              </div>
              {showWarn.text}
              {showWarn.warn}
            </div>
          ),
          handleClose: handleWarnClose,
        }}
      />
    </Container>
  )
}

export default AllUsers
