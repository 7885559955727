import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import {
  Showcase,
  ShowcaseCard,
  Slide,
  StatisticBox,
  // VirtualAccount,
} from '../components'
import apiService from '../services/apiService'
import { addaccountImgSvg, mngaccountImgSvg, analyzeImgSvg } from '../assets'
// import { getOriginIcon } from '../services/lucaFunctions'

function Home(props) {
  const { auth, setting } = props
  const { notification } = setting
  if (notification) console.log('t')

  const fakeGrids = [
    {
      title: '開戶申請',
      content: `立即申請媒體廣告帳號！LUCA提供多項主流媒體帳戶申請，含括Meta、Google、LINE LAP、Criteo、Taboola…等。`,
      imgSrc: addaccountImgSvg,
      link: '/applyAccount',
      btnText: '前往開戶',
      // footer: (
      //   <div className="d-flex mt-auto px-2 justify-content-center">
      //     {['facebook', 'google', 'Twitter', 'LinkedIn', 'Criteo'].map(
      //       (platform, i) => (
      //         <span
      //           key={i}
      //           className="d-flex px-2"
      //           title={platform}
      //           style={{
      //             cursor: 'pointer',
      //           }}
      //           aria-hidden
      //         >
      //           {getOriginIcon(platform, '22px', 'auto')}
      //         </span>
      //       )
      //     )}
      //   </div>
      // ),
    },
    {
      title: '帳戶儲值申請',
      content:
        'LUCA將各媒體平台之儲值功能進行整合，無需個別逐一申請，以達到最省時便利的管理模式。',
      imgSrc: mngaccountImgSvg,
      link: '/applyBilling',
      btnText: '帳戶管理',
    },
    {
      title: '廣告投放與優化',
      content: `立即為您的廣告帳號加值！
LUCA整合各媒體平台儲值功能，不須跨平台個別管理，達到省時便捷管理模式。
`,
      imgSrc: analyzeImgSvg,
      link: '/Accounts',
      btnText: '廣告投放與優化',
    },
    // {
    //   title: '廣告數據報表',
    //   content:
    //     'LUCA提供多項媒體平台投放數據資料整合、資料視覺化及報表訂閱功能，並可使用AI輔助分析廣告成效內容與提放建議。',
    //   imgSrc: mngaccountImgSvg,
    //   link: '/Reports',
    //   btnText: '廣告數據報表',
    // },
  ]

  const [slides, setslides] = useState([])
  const [accounts, setaccounts] = useState(0)
  const [forms, setforms] = useState(undefined)
  useEffect(() => {
    const getArticles = async () => {
      const res = await apiService.data({
        path: 'luca/insight/article',
        method: 'get',
      })
      setslides(res)
    }
    const getAccounts = async () => {
      const res = await apiService.data({
        path: `luca/accountUser/${auth.auth.user_id}/${auth.account.business_id}`,
        method: 'get',
      })
      setaccounts(res.length)
    }
    const getForms = async () => {
      const res = await apiService.data({
        path: `luca/form/${auth.account.business_id}/${auth.auth.user_id}`,
        method: 'get',
      })
      setforms(res)
    }
    getArticles()
    if (auth.auth.user_id) {
      getAccounts()
      getForms()
    }
  }, [auth.auth.user_id, auth.account.business_id])

  return (
    <Container fluid className="p-4 d-flex card">
      <Row className="h-33">
        {/* 公告欄 + 帳戶總覽 */}
        <Col xs="3" className="d-flex flex-column h-100">
          <div className="text-white slides pt-0 h-100">
            {slides.length === 0 ? (
              <Card className="p-0 h-100 d-flex">
                <div className="m-auto d-flex h-100 w-100 card">
                  <div className="m-auto">
                    <Spinner
                      animation="border"
                      variant="luca"
                      size="lg"
                      style={{
                        animation: 'spinner-border 1.5s linear infinite',
                      }}
                    />
                    <h6 className="text-luca py-2">資料載入中...</h6>
                  </div>
                </div>
              </Card>
            ) : (
              <Slide
                setting={{
                  interval: 8000,
                  content: slides,
                }}
              />
            )}
          </div>
        </Col>

        {/* 主功能欄 */}
        <Col xs="9" className="h-100 d-flex ps-1">
          <div className="card h-100 w-100" aria-hidden>
            <div className="my-auto">
              <Showcase
                setting={{
                  hasIcon: true,
                  title: `${
                    auth.status === 'authed'
                      ? `Hi ! ${auth.auth.name} ，歡迎回到 LUCA，今天想做點什麼呢 ？`
                      : `Hi ! 歡迎來到 LUCA，立即開始您的廣告管理吧！`
                  } `,
                  titleEng: `${
                    auth.status === 'authed'
                      ? `Hi! ${auth.auth.name}, Welcome back to LUCA, have you any plan today?`
                      : `Hi! Welcome to LUCA, let's start your advertising management!`
                  } `,
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="h-67">
        <Col xs={3} className="pt-3 h-100">
          {/* <div
            className="h-47 home-pofile-card"
            title="點擊查看完整資訊"
            style={{ cursor: 'pointer' }}
          >
            <Link to="/Info">
              <StatisticBox
                setting={{
                  title: auth.account.name,
                  titleEng: 'Wavenet Technology',
                  data1: `客戶編號｜${auth.account.setting['客戶編號']}`,
                  data2: `統一編號｜${auth.account.setting['統一編號']}`,
                  data3: `專屬帳戶｜富邦(012)`,
                  data4: (
                    <VirtualAccount
                      setting={{
                        business_id: auth.account.business_id,
                        bankCode: '012',
                      }}
                    />
                  ),
                }}
              />
            </Link>
          </div> */}
          <div className="h-100 pt-0">
            <StatisticBox
              setting={{
                title: '帳 戶 通 知 總 覽',
                titleEng: 'Notification Overview',
                // data1: `您 有 ${
                //   notification.filter((n) => n.status === 'Unread').length
                // } 則 未 讀 通 知`,
                data2: `廣 告 帳 戶 總 數 為 ${accounts} 個`,
                data3: `審 核 中 的 申 請 為 ${
                  forms
                    ? forms.filter(
                        (r) =>
                          (r.status || r.setting.status) === '處 理 中' ||
                          (r.status || r.setting.status) === '待 處 理'
                      ).length
                    : 0
                } 個`,
                data4: `待 回 覆 的 申 請 為 ${
                  forms
                    ? forms.filter(
                        (r) => (r.status || r.setting.status) === '已 退 回'
                      ).length
                    : 0
                } 個`,
              }}
            />
          </div>
        </Col>
        <Col xs={9} className="h-100 d-flex ps-1">
          {fakeGrids.map((grid) => (
            <Col
              xs={4}
              key={grid.title}
              className="home-function-cards pt-3 px-0 h-100"
              title="點 擊 前 往"
            >
              <ShowcaseCard setting={grid} />
            </Col>
          ))}
        </Col>
      </Row>
    </Container>
  )
}

Home.propTypes = {
  auth: PropTypes.shape().isRequired,
  setting: PropTypes.shape().isRequired,
}

export default Home
